import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Column, Container, Row } from '../Grid';
import './styles.scss';

const HeroArchive = ({ heroBanner, banner, className, children }) => {
  const getClassnames = () =>
  classNames('hero-archive', banner && 'hero-archive--banner', className);

  return (
    <div className={getClassnames()}>
      <Container className="hero-archive__banner">
        <Row>
          <h1>{heroBanner}</h1>
        </Row>
      </Container>
      <Container className="hero-archive__container">
        <Row>
          <Column>
            {children}
          </Column>
        </Row>
      </Container>
    </div>
  );
};

HeroArchive.defaultProps = {
  heroBanner: false,
  className: null,
  banner: false,
};

HeroArchive.propTypes = {
  /** A hero banner headline copy */
  heroBanner: PropTypes.string,
  /** A custom class name */
  className: PropTypes.string,
  /** Add the banner hero */
  banner: PropTypes.bool,
};

export default HeroArchive;
