import React from 'react';
import classNames from 'classnames';
import Image from '../Image';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import './styles.scss';

const Card = ({
  className,
  image,
  duration,
  title,
  headline,
  subhead,
  children,
}) => {
  const getClassnames = () => classNames('card', className);

  return (
    <div className={getClassnames()}>
      <h3 className="card__title">{title}</h3>
      <Image className="card__image" filename={image} />
      <div className="card__wrapper">
        <h2 className="card__headline">{headline}</h2>
        <p className="card__subhead">{subhead}</p>
        {duration && <p className="card__duration"><Icon name="clock" marginRight /> {duration}</p>}
        <div>{children}</div>
      </div>
    </div>
  );
};

Card.defaultProps = {
  className: null,
  image: null,
};

Card.propTypes = {
  /** A custom class name */
  className: PropTypes.string,
  /** The image file name */
  image: PropTypes.string,
};

export default Card;
